import React from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import CallEnd from '@material-ui/icons/CallEnd';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { LocalAudioTrack, LocalVideoTrack } from 'twilio-video';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
  })
);

export default function EndCallButton() {
  const classes = useStyles();
  const { room, localTracks } = useVideoContext();
  const history = useHistory();
  const audioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  const videoTrack = localTracks.find(track => track.name.includes('camera')) as LocalVideoTrack;

  const handleClick = () => {
    if (audioTrack) {
      audioTrack.stop();
    }
    if (videoTrack) {
      videoTrack.stop();
    }
    room.disconnect();
    history.push('/end');
  };

  return (
    <Tooltip title={'End Call'} onClick={handleClick} placement="top" PopperProps={{ disablePortal: true }}>
      <Fab className={classes.fab} color="primary">
        <CallEnd />
      </Fab>
    </Tooltip>
  );
}
