import React, { createContext, useContext, useReducer, useState } from 'react';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';

export interface StateContextType {
  error: TwilioError | null;
  setError(error: TwilioError | null): void;
  getToken(businessId: string, customerThreadId: string): Promise<string>;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
}

export const StateContext = createContext<StateContextType>(null!);

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useState('default');
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);

  const getToken: StateContextType['getToken'] = (businessId, customerThreadId) => {
    const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/Room';
    const url = `${endpoint}/${businessId}/${customerThreadId}`;

    setIsFetching(true);

    return fetch(url)
      .then(res => {
        if (res.ok) {
          return res.json();
        } else {
          return Promise.reject('An error occured');
        }
      })
      .then(data => {
        setIsFetching(false);
        return data.jwt;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider
      value={{ error, setError, isFetching, getToken, activeSinkId, setActiveSinkId, settings, dispatchSetting }}
    >
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
