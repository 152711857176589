import React from 'react';
import { styled } from '@material-ui/core';

const Container = styled('div')({
  textAlign: 'center',
  padding: '10px 0',
  fontSize: '1.2em',
});

export default function EndOfCall() {
  return <Container>Votre appel vidéo est terminé</Container>;
}
