import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import fscreen from 'fscreen';

import EndCallButton from './EndCallButton/EndCallButton';
import ToggleAudioButton from './ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from './ToggleVideoButton/ToggleVideoButton';

import useRoomState from '../../hooks/useRoomState/useRoomState';
import FlipCameraButton from './FlipCameraButton/FlipCameraButton';
import ToggleFullscreenButton from './ToggleFullscreenButton/ToggleFullscreenButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      position: 'absolute',
      right: '50%',
      transform: 'translate(50%, 0px)',
      bottom: '50px',
      zIndex: 1,
      opacity: 1,
      maxWidth: 'min-content',
      [theme.breakpoints.down('md')]: {
        bottom: `${theme.sidebarMobileHeight + 3}px`,
      },
    },
  })
);

export default function Controls() {
  const classes = useStyles();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';

  return (
    <div className={clsx(classes.container)}>
      <ToggleAudioButton disabled={isReconnecting} />
      <ToggleVideoButton disabled={isReconnecting} />
      <FlipCameraButton disabled={isReconnecting} />
      {fscreen.fullscreenEnabled && <ToggleFullscreenButton />}
      {roomState !== 'disconnected' && <EndCallButton />}
    </div>
  );
}
