// Copied from src/components/MenuBar/ToggleFullscreenButton/ToggleFullscreenButton.tsx

import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';

import useFullScreenToggle from '../../../hooks/useFullScreenToggle/useFullScreenToggle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
  })
);

export default function ToggleFullscreenButton() {
  const [isFullScreen, toggleFullScreen] = useFullScreenToggle();
  const classes = useStyles();

  return (
    <Tooltip title="Full Screen" placement="top" PopperProps={{ disablePortal: true }}>
      <Fab className={classes.fab} onClick={toggleFullScreen}>
        {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      </Fab>
    </Tooltip>
  );
}
