import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { styled, makeStyles, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import Controls from './components/Controls/Controls';
import LocalVideoPreview from './components/LocalVideoPreview/LocalVideoPreview';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';

import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import useVideoContext from './hooks/useVideoContext/useVideoContext';
import { useAppState } from './state';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
});

const Main = styled('main')({
  overflow: 'hidden',
});

const SpinnerWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '5px 0',
});

const useStyles = makeStyles(() =>
  createStyles({
    circularProgress: {
      marginRight: '5px',
    },
  })
);

interface Params {
  businessId: string;
  customerThreadId: string;
}

export default function App() {
  const roomState = useRoomState();
  const { businessId, customerThreadId } = useParams<Params>();
  const { isConnecting, connect, localTracks } = useVideoContext();
  const { getToken, isFetching } = useAppState();
  const [hasTriedToConnect, setHasTriedToConnect] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    // localTracks représente les tracks (vidéo et audio) accepté par l'utilisateur
    // localTracks sera donc vide si l'utilisateur n'a rien accepté
    // et aura 2 éléments si l'utilisateur a accepté la vidéo et l'audio
    // il est préférable d'attendre que l'utilisateur ait accepté la vidéo et l'audio
    // avant de se connecter à la room, sinon la room s'affichera sans vidéo,
    // et l'utilisateur devra mute puis unmute la vidéo afin qu'elle s'affiche
    if (localTracks.length === 2 && !hasTriedToConnect) {
      getToken(businessId, customerThreadId).then(token => connect(token));
      setHasTriedToConnect(true);
    }
  }, [localTracks.length]);

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  return (
    <Container style={{ height }}>
      {isConnecting || isFetching ? (
        <SpinnerWrapper>
          <CircularProgress className={classes.circularProgress} />
          Connecting to the room...
        </SpinnerWrapper>
      ) : (
        <div />
      )}
      <Main>
        {roomState === 'disconnected' ? <LocalVideoPreview /> : <Room />}
        <Controls />
      </Main>
      <ReconnectingNotification />
    </Container>
  );
}
